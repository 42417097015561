import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Nav from './Nav';
import BI from './BI';
import Footer from './Footer';
import Privacy from './Privacy';
import TOS from './TOS';
import Notices from './Notices';
import Notice from './Notice';
import Notfound from './Notfound';
import B2B from './B2B';
import Support from './Support';
import OPL from './OPL';
import TrustedTesters from './TrustedTesters';

const Structures = () => {
    return (
        <BrowserRouter>
            <Nav />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/bi" element={<BI />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/tos" element={<TOS />} />
                <Route path="/notice" element={<Notices />} />
                <Route path="/notice/:id" element={<Notice />} />
                <Route path="/B2B" element={<B2B />} />
                <Route path="/support" element={<Support />} />
                <Route path="/opl" element={<OPL />} />
                <Route path="/trusted-testers" element={<TrustedTesters />} />
                <Route path="*" element={<Notfound />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default Structures;
