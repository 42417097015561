import React from 'react';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';

const TrustedTesters = () => {
    return (
        <>
            <Element name="support" className="p-4 bg-ultralightmain">
                <div className="min-h-screen justify-center items-center flex">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-primary mb-4 mt-12">
                            Goding Trusted Testers Program
                        </h1>
                        <p className="text-lg text-gray-500 mb-8 px-4">
                            갓잉에서 개발 중인 앱을 미리 체험하고, 피드백을 공유해 주세요.
                        </p>
                        <form
                            action="https://goding.us14.list-manage.com/subscribe/post?u=97d4a180e916bd1adc41e0bec&id=333bfa6ba5&f_id=00c563e1f0"
                            method="post"
                            className="pageclip-form flex flex-col items-center"
                        >
                            <input
                                type="email"
                                name="EMAIL"
                                placeholder="이메일 (Apple ID)"
                                className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md"
                                required
                            />
                            <input
                                type="text"
                                name="LNAME"
                                placeholder="성"
                                className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md"
                            />
                            <input
                                type="text"
                                name="FNAME"
                                placeholder="이름"
                                className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md"
                            />
                            <select
                                name="MMERGE7"
                                className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md"
                                required
                            >
                                <option value="iOS">iOS</option>
                            </select>

                            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                <input type="text" name="b_97d4a180e916bd1adc41e0bec_333bfa6ba5" tabIndex="-1" value="" />
                            </div>

                            <span className="text-xs text-gray-500 mb-4">
                                아래 버튼을 누르시면{' '}
                                <Link to="/privacy" className="text-primary">
                                    개인정보 처리방침
                                </Link>
                                에 동의하는 것으로 간주됩니다.
                                <br />
                                베타 프로그램에서 참여한 내용에 대해서 외부로 공유가 어려우며, 처리까지
                                1~2일 정도가 소요될 수 있습니다.
                                <br />
                                베타 프로그램 등록은 Mailchimp를 통해 이루어지며, 프로그램 관련 정보 및 뉴스레터가 전송되고, 언제든지 수신을 거부할 수 있습니다.
                            </span>
                            <button
                                type="submit"
                                className="w-96 p-4 bg-primary text-white font-bold text-xl rounded-md hover:bg-blue-800 transition duration-300 mb-12"
                            >
                                등록하기
                            </button>
                        </form>
                    </div>
                </div>
            </Element>
        </>
    );
};

export default TrustedTesters;