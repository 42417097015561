import Logosad from './logo_sad.svg';
import { Link } from 'react-router-dom';
const Notfound = () => {
    return (
        <div className="h-screen justify-center items-center bg-white flex">
            <div className="text-center">
                <img src={Logosad} alt="Sad face" className="w-28 mx-auto" />
                <h1 className="text-4xl font-bold text-primary mb-2">페이지를 찾을 수 없음</h1>
                <p className="text-2xl text-gray-500 mb-8">요청하신 페이지를 갓잉 서버에서 찾을 수 없습니다.</p>
                <div className="space-x-4 mt-4">
                    <Link to="/" className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">홈으로</Link>
                </div>
            </div>
        </div>
    );
}

export default Notfound;