import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ko';
moment.locale('ko');

const Notice = () => {
    const { id } = useParams();
    const [notice, setNotice] = useState({});
    useEffect(() => {
        fetch(`https://api.goding.us/public/notice/${id}`)
            .then(response => response.json())
            .then(data => setNotice(data))
            .catch(error => console.error('Error fetching notice:', error));
    }, [id]);
    if (notice.title) {
        document.title = `${notice.title} | 갓잉`;
    }
    return (
        <>
                {notice.title ?
                    <div className="px-4 md:px-20 pt-16 md:pt-20 mb-16 md:mb-20">
                        <h1 className='text-4xl font-bold text-primary mb-6'>{notice.title}</h1>
                            <span className="text-gray-800 text-sm mr-2">{moment(notice.timestamp).format('YYYY.MM.DD')}</span>
                            <span className="text-gray-500 text-sm mr-2">{moment(notice.timestamp).fromNow()}</span>
                            <span className="text-gray-800 text-sm ml-2">|</span>
                            <span className="text-gray-800 text-sm ml-3">{notice.writer}</span>
                        <hr className="my-4 border-gray-200" />
                        <p className="text-gray-800 mb-32" style={{lineHeight: "180%"}}>
                            <div dangerouslySetInnerHTML={{ __html: notice.content }} />
                        </p>
                    </div>
                    : <div className="px-4 md:px-20 pt-16 md:pt-20 mb-16 md:mb-20">
                        <h1 className='text-4xl font-bold text-primary mb-8'>공지사항을 불러오고 있습니다...</h1>
                        <p className='text-gray-600'>잠시만 기다려주세요.</p>
                    </div> // Add this closing curly brace
                }
            </>
    );
}

export default Notice;